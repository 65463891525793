/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
div.navi {
  float: left;
  display: block;
  width: 100%;
  list-style: none;
}
div.navi > .item {
  display: list-item;
}
div.navi > .item > .menu {
  position: relative;
  display: block;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
}
div.navi > .item > .menu:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  width: 0;
  height: 4px;
  background-color: #000;
  transition: width 250ms;
}
div.navi > .item > .menu:hover:after,
div.navi > .item > .menu:focus:after {
  width: 20px;
}
div.navi > .item > .menu.path:after {
  width: 20px;
}
div.navi > .item:nth-last-child(3) {
  margin-top: 46px;
  font-weight: bold;
}
div.navi > .item:nth-last-child(2) {
  font-weight: bold;
}
div.navi > .item:last-child {
  font-weight: bold;
}
#home {
  margin-bottom: 45px;
}
.section.section--header {
  position: fixed;
  width: 230px;
}
.section.section--header .desk {
  width: 205px;
  margin-right: 0;
}
.section.section--main,
.section.section--footer {
  float: right;
  width: calc(100% - 230px);
}
.section.section--main .desk,
.section.section--footer .desk {
  float: right;
  width: calc(100% - 70px);
  margin-left: 30px;
  margin-right: 30px;
}
.section.section--footer .copy {
  float: left;
  padding-right: 4px;
  border-right: 1px solid #000;
}
.section.section--footer .services {
  width: auto;
}
.section.section--footer .services > .meta:first-child {
  padding-left: 4px;
}
.cb-goto-top {
  left: 205px;
  right: auto;
  bottom: 35px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 1.53061224%;
  margin-left: 1.53061224%;
}
.area .part {
  margin-right: 1.53061224%;
  margin-left: 1.53061224%;
  width: 96.93877551%;
}
.area .tiny {
  width: 46.93877551%;
}
.area > .slim {
  width: 50%;
}
.area > .slim .foot,
.area > .slim .part {
  margin-right: 3.06122449%;
  margin-left: 3.06122449%;
}
.area > .slim .part {
  width: 93.87755102%;
}
.area > .slim .tiny {
  width: 43.87755102%;
}
.cb-layout3 .area.main {
  width: 100%;
}
.cb-layout3 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout3 .area.main .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main > .slim .part {
  width: 100%;
}
.area,
.cb-layout3 .area {
  float: right;
  width: 103.15789474%;
  margin-left: -1.57894737%;
  margin-right: -1.57894737%;
}
.area.base,
.area.main {
  margin-top: 15px;
}
.area.base:empty,
.area.main:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.area .unit.seam.slim {
  width: 33.33333333%;
}
.area .unit.seam.slim .part {
  width: 90.81632653%;
  margin-left: 4.59183673%;
  margin-right: 4.59183673%;
}
.rolloverunit-large .part.text {
  bottom: 0;
  opacity: 0;
  transition: opacity 250ms;
}
.rolloverunit-large:hover .part.text,
.rolloverunit-large:focus .part.text {
  opacity: 1;
}
#view.cb-layout2 .unit.seam .part.text {
  bottom: 0;
  opacity: 0;
  transition: opacity 250ms;
}
#view.cb-layout2 .unit.seam:hover .part.text,
#view.cb-layout2 .unit.seam:focus .part.text {
  opacity: 1;
}
#view.cb-layout3 .unit .part.text {
  bottom: 0;
  opacity: 0;
  transition: opacity 250ms;
}
#view.cb-layout3 .unit:hover .part.text,
#view.cb-layout3 .unit:focus .part.text {
  opacity: 1;
}
.cb-layout1 .part.pict img.cb-portrait {
  width: 77.36842105%;
}
.part.text,
.part.part--table {
  width: 65.78947368%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */